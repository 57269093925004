import React from 'react';
import {
    Body,
    Content,
    ContentData,
    ContentFormSubscription,
    ContentInvoicesPayment,
    ContentPaymentMethod,
} from "./styled";
import {observer} from "mobx-react";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import Header from "src/presentation/pages/subscription/ui/detail_subscription/header";
import DividerDropDesk from "src/presentation/components/divider";
import Footer from "src/presentation/pages/subscription/ui/detail_subscription/footer";
import {PaymentSubscriptionEntity} from "@dropDesk/domain/entities/payment_subscriptions/payment_subscription_entity";
import DetailPlan from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/detail_plan";
import LastPendingPayment from "src/presentation/pages/subscription/ui/detail_subscription/last_pending_payment";
import {PaymentEntity} from "@dropDesk/domain/entities/payment/payment.entity";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import PaymentMethod from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/payment_method";
import {CompanyPaymentInfo} from "@dropDesk/domain/entities/company/company.entity";
import PaymentInfo from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/payment_info";
import InvoicesHistory from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/invoices_history";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import CancelSubscription from "@dropDesk/presentation/pages/subscription/ui/detail_subscription/cancel_subscription";
import Logs from "src/presentation/pages/subscription/ui/detail_subscription/history_change";
import {CompanyLogsEntity} from "@dropDesk/domain/entities/company/company_logs.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

const DetailSubscription = observer((
        {
            currentSubscription,
            disabled,
            loading,
            onClickSelectPlan,
            onClickChangePlan,
            onClickReactivePlan,
            lastPayment,
            handleClickPayPending,
            handleClickPayWithPix,
            paymentMethod,
            paymentInfo,
            handleEditPaymentInfo,
            handleEditPaymentMethod,
            visibleButtonChangePlan,
            tableInvoices,
            getDataFromPageInvoices,
            onClickCancelSubscription,
            loadingTableInvoices,
            onCLickLogout,
            onClickCancelFutureSubscription,
            visibleCancelButton,
            onClickViewInvoice,
            onClickDownloadInvoice,
            oldSubs,
            hasDowngrade,
            tableSubscriptionLogs,
            getDataFromPageLogs,
            onSelectedRowLog,
            hasUpgrade,
            currentUser
        }: {
            currentSubscription: PaymentSubscriptionEntity,
            disabled: boolean;
            loading: boolean;
            loadingTableInvoices: boolean;
            onClickSelectPlan: () => void
            onClickChangePlan: () => void
            onClickReactivePlan: () => void
            handleClickPayPending: (payment: PaymentEntity) => void
            handleClickPayWithPix: (payment: PaymentEntity) => void
            lastPayment?: PaymentEntity
            paymentMethod?: PaymentMethodEntity
            paymentInfo?: CompanyPaymentInfo
            handleEditPaymentInfo: () => void
            handleEditPaymentMethod: () => void
            visibleButtonChangePlan: boolean
            tableInvoices: ListPaginationEntity<PaymentEntity>,
            getDataFromPageInvoices: (page: number) => void,
            onClickCancelSubscription: () => void
            onCLickLogout: () => void
            onClickCancelFutureSubscription: () => void
            visibleCancelButton: boolean
            onClickViewInvoice: (url: string) => void
            onClickDownloadInvoice: (url: string) => void
            oldSubs?: PaymentSubscriptionEntity
            hasDowngrade: boolean
            tableSubscriptionLogs: ListPaginationEntity<CompanyLogsEntity>,
            getDataFromPageLogs: (page: number) => void,
            onSelectedRowLog: (log: CompanyLogsEntity) => void
            hasUpgrade: boolean
            currentUser?: UserEntity | null
        }
    ) => {

        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;

        return (
            <Content>
                <ContentFormSubscription background={colors.onBackground}>
                    <Header
                        text={'Informações da assinatura'}
                        onCLickLogout={() => onCLickLogout()}
                    />
                    <ContentData>
                        <DividerDropDesk/>
                        {lastPayment && (lastPayment.isPending || lastPayment.isReproved) && !currentSubscription.isCancelled &&
                            <LastPendingPayment
                                lastPayment={lastPayment}
                                handleClickPayWithPix={handleClickPayWithPix}
                                onClickViewInvoice={onClickViewInvoice}
                                onClickDownloadInvoice={onClickDownloadInvoice}
                                currentSubscription={currentSubscription}
                            />
                        }
                        <Body>
                            <DetailPlan
                                onClickSelectPlan={onClickSelectPlan}
                                onClickChangePlan={onClickChangePlan}
                                onClickReactivePlan={onClickReactivePlan}
                                isFreePlan={currentSubscription.isFreeActiveSubscription}
                                isActivePlan={currentSubscription.isPremiumActiveSubscription}
                                isCanceledScheduled={currentSubscription.isCanceledScheduled(currentUser)}
                                isExpiredPlan={currentSubscription.isExpired()}
                                isWaitingPlan={currentSubscription.isWaitingPayment}
                                disabled={disabled}
                                loading={loading}
                                planName={currentSubscription.planName}
                                nextDueDateDDMMYYYY={currentSubscription.nextChargeDateDDMMYYYY}
                                planValue={currentSubscription.isWaitingPayment && currentSubscription.lastPayment?.value ? currentSubscription.lastPayment?.value : currentSubscription.planValue(oldSubs)}
                                visibleButtonChangePlan={visibleButtonChangePlan}
                                daysToExpireSubs={currentSubscription.daysToExpireSubs(oldSubs)}
                                onClickCancelFutureSubscription={onClickCancelFutureSubscription}
                                visibleCancelButton={visibleCancelButton}
                                quantityUsersNewSubs={currentSubscription.quantityOfUsers}
                                oldSubs={oldSubs}
                                hasDowngrade={hasDowngrade}
                                hasUpgrade={hasUpgrade}
                                isLegacy={currentSubscription.plan.legacy}
                            />
                            {(!!paymentMethod || !!paymentInfo) &&
                                <ContentPaymentMethod>
                                    {paymentMethod &&
                                        <PaymentMethod
                                            paymentMethod={paymentMethod}
                                            handleEditPaymentMethod={handleEditPaymentMethod}
                                            loading={loading}
                                            disabled={disabled}
                                        />
                                    }

                                    {(!!paymentInfo || currentSubscription.isPremiumActiveSubscription) &&
                                        <PaymentInfo
                                            paymentInfo={paymentInfo}
                                            handleEditPaymentInfo={handleEditPaymentInfo}
                                            loading={loading}
                                            disabled={disabled}
                                        />
                                    }
                                </ContentPaymentMethod>
                            }
                            <ContentInvoicesPayment>
                                <InvoicesHistory
                                    tableInvoices={tableInvoices}
                                    loading={loadingTableInvoices}
                                    getDataFromPageInvoices={getDataFromPageInvoices}
                                    handleClickPayPending={handleClickPayPending}
                                    onClickViewInvoice={onClickViewInvoice}
                                    currentSubscription={currentSubscription}
                                />
                            </ContentInvoicesPayment>
                            <ContentInvoicesPayment>
                                <Logs
                                    loading={loadingTableInvoices}
                                    tableSubscriptionLogs={tableSubscriptionLogs}
                                    getDataFromPageLogs={getDataFromPageLogs}
                                    onSelectedRowLog={onSelectedRowLog}
                                />
                            </ContentInvoicesPayment>
                            {(currentSubscription.isPremiumActiveSubscription || currentSubscription.isPending) &&
                                <ContentInvoicesPayment>
                                    <CancelSubscription
                                        disabled={disabled}
                                        loading={loading}
                                        currentSubscription={currentSubscription}
                                        onClickCancelSubscription={onClickCancelSubscription}
                                    />
                                </ContentInvoicesPayment>
                            }
                        </Body>
                        <DividerDropDesk/>
                    </ContentData>
                    <Footer/>
                </ContentFormSubscription>
            </Content>
        )

    }
);
export default DetailSubscription;
