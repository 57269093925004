import secureLocalStorage from "react-secure-storage";
import {FirebaseStoragePaths, SecureStorageKeys} from "@dropDesk/domain/entities/constants/storage_keys";
import {AuthHashEntity} from "@dropDesk/domain/entities/auth/auth_hash.entity";
import forge from "node-forge";
import {auth, getTokenAppCheck, storage} from "@dropDesk/data/clients/firebase.client";
import {getDownloadURL, ref} from "firebase/storage";
import {removeLineBreaks} from "@dropDesk/utils/helpers/string_helper";
import {injectable} from "inversify";
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {AuthRemoteDataSourceImpl} from "@dropDesk/data/data_source/auth/auth_remote.datasource";
import {getToken, getTokenDecoded} from "@dropDesk/data/data_source/token/token.datasource";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {OPEN_ROUTES} from "@dropDesk/data/clients/http.client";
import {ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {navigate} from "@dropDesk/utils/helpers/navigation";
import {UserInfoEntity} from "@dropDesk/domain/entities/user/user.entity";

@injectable()
export class AuthHashClient {
    retries: number = 0;

    public getCert = async (): Promise<string | null> => {
        try {
            let result: string | null = secureLocalStorage.getItem(SecureStorageKeys.certKey) as string | null;
            if (!result) {
                await this.refreshCerts();
                result = secureLocalStorage.getItem(SecureStorageKeys.certKey) as string | null;
            }
            return result;
        } catch (e) {
            console.log('Error on get Cert', e);
            return null;
        }
    }

    public static getAuthHash = (): AuthHashEntity => {
        let authHash: AuthHashEntity | null = (secureLocalStorage.getItem(SecureStorageKeys.hash) as AuthHashEntity | null);
        if (!authHash) {
            authHash = AuthHashClient.persistAuthHash();
        }
        return authHash;
    }

    public getAuthHashEncrypted = (): string | null => {
        try {
            const authHash = AuthHashClient.getAuthHash();
            const publicKey: forge.pki.rsa.PublicKey | null = this.getPublicKeyFromStorage();
            authHash["timestamp"] = new Date().getTime();
            if (publicKey == null) return null;
            const encryptedBase64 = this.encrypt(publicKey, authHash as unknown as Record<string, unknown>);
            return encryptedBase64;
        } catch (e) {
            console.log('Error on auth hash', e);
            return null;
        }
    }

    public static persistAuthHash(user?: UserInfoEntity): AuthHashEntity {
        const hash = new AuthHashEntity({
            firebaseId: auth.currentUser?.uid ?? '',
            email: user?.email ? user?.email.toLowerCase() : auth.currentUser?.email ? auth.currentUser?.email.toLowerCase() : '',
            role: user?.role ?? '',
            idCompany: user?.idCompany ?? '',
            id: user?.id ?? '',
        });
        secureLocalStorage.setItem(SecureStorageKeys.hash, hash);
        return hash;
    }

    public static replaceByToken(token: Record<string, any>): void {
        const authHash = AuthHashClient.getAuthHash();
        const newUserInfo = new UserInfoEntity({
            email: token?.email ? token?.email.toLowerCase() : authHash?.email ? authHash?.email.toLowerCase() : '',
            role: token?.role ?? authHash?.role,
            idCompany: token?.idCompany ?? authHash?.idCompany,
            id: token?.idUser ?? authHash?.id,
        });
        this.persistAuthHash(newUserInfo);
    }

    public refreshCerts = async (): Promise<boolean> => {
        try {
            await Promise.all([
                this.downloadPublicKey(),
                this.downloadCertKey(),
            ]);
            return true;
        } catch (e) {
            console.log('Error on refresh certs', e);
            return false;
        }
    }

    public downloadPublicKey = async (): Promise<void> => {
        try {
            const appCheckToken = await getTokenAppCheck();
            const refStorage = ref(storage, FirebaseStoragePaths.publicKey);
            console.log('appCheckToken', appCheckToken);
            const downloadURL = await getDownloadURL(refStorage);
            const response = await axios.get(downloadURL, {
                headers: {
                    'X-Firebase-AppCheck': appCheckToken ?? ''
                }
            });
            const publicKey = await response.data;
            secureLocalStorage.setItem(SecureStorageKeys.publicKey, publicKey);
        } catch (e) {
            console.log('Error on download public key', e);
        }
    }

    public downloadCertKey = async (): Promise<void> => {
        try {
            const appCheckToken = await getTokenAppCheck();
            const refStorage = ref(storage, FirebaseStoragePaths.certKey);
            const downloadURL = await getDownloadURL(refStorage);
            console.log('appCheckToken', appCheckToken);
            const response = await axios.get(downloadURL, {
                headers: {
                    'X-Firebase-AppCheck': appCheckToken ?? ''
                }
            });
            const certKey = await response.data;
            const certKeyRemoveLineBreaks = removeLineBreaks(certKey);
            secureLocalStorage.setItem(SecureStorageKeys.certKey, certKeyRemoveLineBreaks);
        } catch (e) {
            console.log('Error on download cert key', e);
        }
    }

    public retry = async (
        resolve: (value: (AxiosResponse<any, any> | PromiseLike<AxiosResponse<any, any>>)) => void,
        reject: (reason?: any) => void,
        error: AxiosError<any, any>,
        api: AxiosInstance,
        forceRefreshToken = false
    ): Promise<void> => {
        if (this.retries > 2) {
            await this.logoutFirebase(RoutesEnum.login);
            return reject(error);
        }

        const shouldRetry = await this.refreshCerts();
        if (shouldRetry) {
            this.retries++;
            const request: AxiosRequestConfig<any> = error.config;
            await this.setHeaders(request, forceRefreshToken);
            return resolve(api(request));
        }
    }


    public responseInterceptor = async (error: any, api: AxiosInstance): Promise<AxiosResponse<any, any>> => {
        return new Promise<AxiosResponse<any, any>>(async (resolve, reject) => {
            if (axios.isAxiosError(error) && error.response) {

                const response = error.response as AxiosResponse;
                const forceRefreshToken = response.data.error === 'no_valid_token' || response.data.error === 'expired_token';

                const errorActions: Record<string, any> = {
                    'no_company_claims': {action: () => this.logoutFirebase(RoutesEnum.login)},
                    'email_not_verified': {action: () => navigate(RoutesEnum.confirmEmail)},
                    'revoked_token': {action: () => this.logoutFirebase(RoutesEnum.login)},
                    'user_blocked': {action: () => this.logoutFirebase(RoutesEnum.login)},
                    'no_valid_token': {action: () => this.logoutFirebase(RoutesEnum.login)},
                    'connections_exceeded': {action: () => this.handleConnectionExceeded()},
                    'company_dont_have_valid_payment_subscription': {action: () => this.handleSubscriptionExpired()},
                    'company_expired_payment_subscription': {action: () => this.handleSubscriptionExpired()},
                    'auth_exception': {action: () => this.retry(resolve, reject, error, api, forceRefreshToken)},
                };

                const errorMessage: string = response.data.message === 'auth_exception' ? response.data.message : response.data.error;
                const result = errorActions[errorMessage];
                if (result) {
                    await result.action();
                }
            }
            return reject(error);
        });
    }

    private logout = async (route: RoutesEnum): Promise<void> => {
        const auth = new AuthRemoteDataSourceImpl();
        await auth.logout();
        navigate(route);
    }

    private logoutFirebase = async (route: RoutesEnum): Promise<void> => {
        const auth = new AuthRemoteDataSourceImpl();
        await auth.logoutFirebase();
        navigate(route);
    }

    private handleConnectionExceeded = async (): Promise<void> => {
        navigate(RoutesEnum.connectionsExceeded);
    }

    private handleSubscriptionExpired = (): void => {
        const user = getTokenDecoded();
        if (user && user.admin === true) {
            navigate(RoutesEnum.subscription);
        } else {
            navigate(RoutesEnum.subscriptionExpired);
        }
    }

    private matchUrl = (url: string, routes: string[]) => {
        let result = false;
        for (const item of routes) {
            const check = url.indexOf(item);
            if (check > -1) result = true;
        }

        return result;
    }

    public setHeaders = async (axiosRequest: AxiosRequestConfig<any>, forceRefreshToken = false): Promise<void> => {
        if (this.matchUrl(axiosRequest.url ?? '', OPEN_ROUTES)) {
            axiosRequest.headers!['x-api-key'] = `${process.env.PUBLIC_TOKEN}`;
        }
        const token = await getToken(forceRefreshToken);
        if (token) {
            axiosRequest.headers!['Authorization'] = `Bearer ${token}`;
        }
        axiosRequest.headers!['x-client-cert'] = await this.getCert() ?? '';
        const hash = this.getAuthHashEncrypted() ?? '';
        axiosRequest.headers!['hash'] = hash;
    }


    private getPublicKeyFromStorage = (): forge.pki.rsa.PublicKey | null => {
        try {
            const publicKey: string | null = secureLocalStorage.getItem(SecureStorageKeys.publicKey) as string | null;
            if (!publicKey) return null;
            return forge.pki.publicKeyFromPem(publicKey);
        } catch (e) {
            throw new ServerError('public_key_not_found');
        }
    }

    private encrypt = (publicKey: forge.pki.rsa.PublicKey, data: Record<string, unknown>): string => {
        try {
            const encrypted = publicKey.encrypt(JSON.stringify(data), 'RSA-OAEP', {
                md: forge.md.sha256.create(),
            });
            const encryptedBase64 = forge.util.encode64(encrypted);
            return encryptedBase64;
        } catch (e) {
            throw new ServerError('encrypt_error');
        }
    }

}
