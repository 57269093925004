import {inject, injectable} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {ListUserUseCase} from "@dropDesk/domain/use_case/user/list_users_pagination.usecase";
import {SetUserUseCase} from "@dropDesk/domain/use_case/user/set_user.usecase";
import {FindByPKUserUseCase} from "@dropDesk/domain/use_case/user/findbypk_user.usecase";
import {CreateObjectUrlBlobUseCase} from "@dropDesk/domain/use_case/io/create_object_url_blob.usecase";
import {RestoreUserUseCase} from "@dropDesk/domain/use_case/user/restore_user.usecase";
import {DeleteUserUseCase} from "@dropDesk/domain/use_case/user/delete_user.usecase";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {SectorUsersEntity} from "@dropDesk/domain/entities/sectors_users/sectors_users.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {ForgotUserUseCase} from "@dropDesk/domain/use_case/user/forgot_user.usecase";
import {ChangePasswordUserUseCase} from "@dropDesk/domain/use_case/user/change_password_user.usecase";
import {ActivateAccountUseCase} from "@dropDesk/domain/use_case/user/activate_account_user.usecase";
import {TransferUserUseCase} from "@dropDesk/domain/use_case/user/transfer_user.usecase";
import {ChangeEmailUserUseCase} from "@dropDesk/domain/use_case/user/change_email_user.usecase";
import {SetThemeUserUseCase} from "@dropDesk/domain/use_case/user/set_theme_user.usecase";
import {newUser} from "@dropDesk/presentation/pages/user/controller/new_user";
import {ExportUsersSubscriptionUseCase} from "@dropDesk/domain/use_case/user/export_data_subscription.usecase";
import {ExportDataUserUseCase} from "@dropDesk/domain/use_case/user/export_data.usecase";
import {StatusImport} from "@dropDesk/domain/entities/import_data/import_data_enum";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {Subscription} from "rxjs";
import {
    getIdCompanyByLocalStorage,
    initializeConfigurationLocalStorage,
    preventMissingPage,
    setSearchParamLocalStorage
} from "@dropDesk/utils/helpers/common";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {navigate} from "@dropDesk/utils/helpers/navigation";

configure({
    enforceActions: "always",
});


@injectable()
export class UserController {
    private readonly _listUserUseCase: ListUserUseCase;
    private readonly _setUserUseCase: SetUserUseCase;
    private readonly _findByPKUserUseCase: FindByPKUserUseCase;
    private readonly _createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase;
    private readonly _restoreUseCase: RestoreUserUseCase;
    private readonly _deleteUseCase: DeleteUserUseCase;
    private readonly _changePasswordUseCase: ChangePasswordUserUseCase;
    private readonly _forgotUserUseCase: ForgotUserUseCase;
    private readonly _activateAccountUseCase: ActivateAccountUseCase;
    private readonly _transferUserUseCase: TransferUserUseCase;
    private readonly _changeEmailUserUseCase: ChangeEmailUserUseCase;
    private readonly _setThemeUserUseCase: SetThemeUserUseCase;
    private readonly _exportUsersSubscriptionUseCase: ExportUsersSubscriptionUseCase;
    private readonly _exportDataUserUseCase: ExportDataUserUseCase;

    constructor(
        @inject(ListUserUseCase) listUserUseCase: ListUserUseCase,
        @inject(SetUserUseCase) setUserUseCase: SetUserUseCase,
        @inject(FindByPKUserUseCase) findByPKUserUseCase: FindByPKUserUseCase,
        @inject(CreateObjectUrlBlobUseCase) createObjectURLBlobUseCase: CreateObjectUrlBlobUseCase,
        @inject(RestoreUserUseCase) restoreUseCase: RestoreUserUseCase,
        @inject(DeleteUserUseCase) deleteUseCase: DeleteUserUseCase,
        @inject(ChangePasswordUserUseCase) changePasswordUseCase: ChangePasswordUserUseCase,
        @inject(ForgotUserUseCase) forgotUserUseCase: ForgotUserUseCase,
        @inject(ActivateAccountUseCase) activateAccountUseCase: ActivateAccountUseCase,
        @inject(TransferUserUseCase) transferUserUseCase: TransferUserUseCase,
        @inject(ChangeEmailUserUseCase) changeEmailUserUseCase: ChangeEmailUserUseCase,
        @inject(SetThemeUserUseCase) setThemeUserUseCase: SetThemeUserUseCase,
        @inject(ExportUsersSubscriptionUseCase) exportUsersSubscriptionUseCase: ExportUsersSubscriptionUseCase,
        @inject(ExportDataUserUseCase) exportDataUserUseCase: ExportDataUserUseCase,
    ) {
        makeObservable(this);
        this._listUserUseCase = listUserUseCase;
        this._setUserUseCase = setUserUseCase;
        this._findByPKUserUseCase = findByPKUserUseCase;
        this._createObjectURLBlobUseCase = createObjectURLBlobUseCase;
        this._restoreUseCase = restoreUseCase;
        this._deleteUseCase = deleteUseCase;
        this._changePasswordUseCase = changePasswordUseCase;
        this._forgotUserUseCase = forgotUserUseCase;
        this._activateAccountUseCase = activateAccountUseCase;
        this._transferUserUseCase = transferUserUseCase;
        this._changeEmailUserUseCase = changeEmailUserUseCase;
        this._setThemeUserUseCase = setThemeUserUseCase;
        this._exportUsersSubscriptionUseCase = exportUsersSubscriptionUseCase;
        this._exportDataUserUseCase = exportDataUserUseCase;
    }

    newUser: UserEntity = newUser();

    table = new ListPaginationEntity<UserEntity>({
        pages: 0,
        page: 0,
        limit: Math.floor((window.innerHeight - 230) / 52),
        totalRows: 0,
        data: observable.array([])
    });

    newExportation = new ExportDataEntity({
        idCompany: getIdCompanyByLocalStorage(),
        id: '',
        status: StatusImport.init,
        progress: 0,
        rows: 0,
        table: 'users',
        responseStatus: 200
    });

    @observable
    loading = false;

    @observable
    tableUsers: ListPaginationEntity<UserEntity> = this.table;

    @observable
    pagesData: Record<number, UserEntity[]> = {};

    @observable
    user?: UserEntity;

    @observable
    searchParam = '';

    @observable
    role: UserRole = UserRole.attendant;

    @observable
    userNotFound: boolean = false;

    @observable
    listOnlyDeleted = false;

    @observable
    exportation: ExportDataEntity = this.newExportation;

    @observable
    rowSelectionUsers: UserEntity[] = [];

    @observable
    loadingMessage?: string | null = null;

    @observable
    subscriptionExport?: Subscription;

    @action
    setRowSelectionUsers(users: UserEntity[]) {
        this.rowSelectionUsers = users;
    }

    @action
    changePermissionAdmin = (value: boolean) => {
        this.setUser(this.user!.copyWith({
            permissionAdmin: value,
            permissionScreenUsers: value,
            permissionScreenClients: value,
            permissionScreenSectors: value,
            permissionScreenWarnings: value,
            permissionCancelTicket: value,
            permissionScreenReports: value,
            permissionDeleteData: value,
            extraInfo: this.user?.extraInfo?.copyWith({
                permissionJustListUserTicket: false
            })
        }));
    }


    @action
    setExportation(exportation: ExportDataEntity) {
        this.exportation = exportation;
    }

    @action
    setListOnlyDeleted(value: boolean) {
        this.listOnlyDeleted = value;
        this.removeLineSelectRow();
        this.resetTable();
        this.list(this.searchParam);
    }

    @action
    setSearchParam(value: string) {
        this.searchParam = value;
        setSearchParamLocalStorage(value);
    }

    @action
    setRole(role: UserRole) {
        this.role = role;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }


    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    createObjectURLBlobUseCase(file: File): string {
        return this._createObjectURLBlobUseCase.call(file);
    }

    @action
    setUser(user: UserEntity) {
        this.user = user;
    }

    @action
    clearUrlImage() {
        this.setUser(this.user!.copyWith({
            urlImageProfile: null,
            replaceUrlImageProfileIfNull: true,
        }))
    }

    @action
    getDataFromPage = async (page: number): Promise<void> => {
        if (this.pagesData[page]) {
            this.setTableUsers(this.tableUsers.copyWith({
                ...this.tableUsers,
                page: page,
                data: this.pagesData[page],
            }))

        } else {
            this.setTableUsers(this.tableUsers.copyWith({
                ...this.tableUsers,
                page: page,
            }))
            return this.list(this.searchParam);
        }
    }

    pushContactData(value: ListPaginationEntity<UserEntity>) {
        this.setTableUsers(this.tableUsers.copyWith({
            ...value,
            data: [...this.tableUsers.data, ...value.data]
        }));
    }

    @action
    getDataFromPageContacts = async (): Promise<void> => {
        if (this.tableUsers.hasMore) {
            this.startLoading();
            const newPage = this.tableUsers.page + 1;
            const response = await this._listUserUseCase.call(newPage, this.searchParam, this.tableUsers.limit, false, UserRole.userClient, undefined, undefined, true);
            this.pushContactData(response);
            this.stopLoading();
        }
    }

    @action
    setUserNotFound(value: boolean) {
        this.userNotFound = value;
    }

    @action
    removeLineSelectRow() {
        this.setRowSelectionUsers([]);
    }

    @action
    initialize(heightGarbage = 0) {
        const routeName = RoutesEnum.users;
        const {lastPage, initSearchParam} = initializeConfigurationLocalStorage(routeName);
        this.tableUsers.limit = Math.floor((window.innerHeight - 265 - 10 + heightGarbage) / 52);
        this.tableUsers.page = lastPage;
        this.searchParam = initSearchParam;
        this.list(initSearchParam);
    }

    @action
    initializeContacts(heightGarbage: number) {
        const routeName = RoutesEnum.contacts;
        const {lastPage, initSearchParam} = initializeConfigurationLocalStorage(routeName);
        this.tableUsers.limit = Math.floor((window.innerHeight - 265 - 10 + heightGarbage) / 52);
        this.tableUsers.page = lastPage;
        this.searchParam = initSearchParam;
        this.setRole(UserRole.userClient);
        this.list(initSearchParam);
    }

    @action
    set = async (
        userLogged: UserEntity,
        onUpdateCurrentUser: (user: UserEntity) => void,
        onSuccess?: (key: string) => void
    ): Promise<void> => {
        try {

            this.startLoading("user.loadingSave");
            const isNew = this.user!.isUnsaved();

            if (this.user!.isUnsaved()) {
                this.setUser(this.user!.copyWith({
                    id: generateUUIDV4()
                }))
            }

            const user = await this._setUserUseCase.call(this.user!, isNew);
            if (user.id === userLogged.id) {
                onUpdateCurrentUser(user);
            }

            if (onSuccess) {
                if (isNew) {
                    onSuccess("user.success.create");
                } else {
                    onSuccess("user.success.save");
                }
            }
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }

    }

    @action
    transferUser = async (
        idNewClient: string,
        idUser: string,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {

            this.startLoading("user.loadingTransfer");
            await this._transferUserUseCase.call(idNewClient, idUser);
            this.removeLineSelectRow();
            onSuccess("user.success.transfer");
            this.stopLoading()

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    delete = async (onSuccess: (key: string) => void): Promise<void> => {
        try {

            this.startLoading("user.loadingDelete");
            await this._deleteUseCase.call(this.rowSelectionUsers);
            this.removeLineSelectRow();
            onSuccess("user.success.delete");
            this.stopLoading()

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    @action
    restore = async (onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading("user.loadingRestore");
            await this._restoreUseCase.call(this.rowSelectionUsers, this.role);
            this.removeLineSelectRow();
            onSuccess("user.success.restore");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.removeLineSelectRow();
            this.stopLoading();
        }
    }

    @action
    makeUser(): void {
        this.setUser(this.newUser);
    }

    @action
    getUser = async (id: string, role?: UserRole): Promise<void> => {
        try {
            this.startLoading("user.loadingInit");
            const user = await this._findByPKUserUseCase.call(id, role);
            this.setUser(user);
            this.stopLoading();
        } catch (err: any) {
            this.setUserNotFound(true);
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    changePassword = async (
        renewToken: string,
        password: string,
        onSuccess: (key: string, email: string) => void
    ): Promise<void> => {
        try {

            this.startLoading("user.new_password.loading");
            const accountAction = await this._changePasswordUseCase.call(renewToken, password);
            onSuccess("user.success.changePassword", accountAction.email);
            this.stopLoading();

        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    changeEmail = async (
        oldEmail: string,
        newEmail: string,
        isCurrentUserChanged: boolean,
        onSuccess: (key: string, newEmail: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("user.activate_account.change");
            await this._changeEmailUserUseCase.call(oldEmail, newEmail, isCurrentUserChanged);
            onSuccess("user.success.changeEmail", newEmail);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    firstAccess = async (
        renewToken: string,
        onSuccess: (key: string, email: string) => void,
        password?: string,
    ): Promise<void> => {
        try {
            this.startLoading("user.activate_account.loading");
            const accountAction = await this._changePasswordUseCase.call(renewToken, password);
            onSuccess("user.success.first_access", accountAction.email);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    setTheme = async (
        theme: string,
        onUpdateCurrentUser?: (user: UserEntity) => void,
    ): Promise<void> => {
        try {
            this.startLoading("user.theme.change");
            await this._setThemeUserUseCase.call(theme);
            if (onUpdateCurrentUser && this.user) {
                onUpdateCurrentUser(this.user);
            }
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }


    @action
    forgot = async (
        email: string,
        onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("forgot_password.loading");
            await this._forgotUserUseCase.call(email);
            onSuccess("user.success.forgot");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    activateAccount = async (
        email: string,
        onSuccess: (key: string, email: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("user.activate_account.send");
            await this._activateAccountUseCase.call(email);
            onSuccess("user.success.activateAccount", email);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }


    @action
    setTableUsers(value: ListPaginationEntity<UserEntity>) {
        this.tableUsers = value;
        const isNecessaryReload = preventMissingPage(this.tableUsers);
        if (isNecessaryReload) {
            this.list('');
        } else {
            this.pagesData[this.tableUsers.page] = this.tableUsers.data;
        }
    }


    @action
    list = async (searchParam: string, idClient?: string, idSector?: string, listOnlyContactWithWhatsApp?: boolean): Promise<void> => {
        try {
            this.startLoading();
            if (searchParam !== this.searchParam) {
                this.resetTable();
            }
            this.setSearchParam(searchParam ?? '');
            const response = await this._listUserUseCase.call(this.tableUsers.page, this.searchParam, this.tableUsers.limit, this.listOnlyDeleted, this.role, idClient, idSector, listOnlyContactWithWhatsApp);
            this.setTableUsers(response);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    resetTable() {
        this.pagesData = {};
        this.setTableUsers(this.table);
    }


    isCurrentUser(userLogged: UserEntity, user?: UserEntity): boolean {
        return userLogged.id === user?.id;
    }

    currentUserIsAdmin(userLogged: UserEntity): boolean {
        return !!userLogged.permissionAdmin;
    }

    permitDeleteUser(user: UserEntity, userLogged: UserEntity): boolean {
        return !this.isCurrentUser(user, userLogged) && this.currentUserIsAdmin(userLogged) && !user.isUnsaved() && !user.userMain && userLogged.permissionDeleteData;
    }

    @action
    onInsertSectorsLinked = (sector: SectorEntity): void => {
        this.setUser(this.user!.copyWith({
            sectors: [...this.user!.sectors, new SectorUsersEntity({
                idCompany: this.user!.idCompany,
                idSector: sector.id,
                idUser: this.user!.id,
                sector: sector,
                user: this.user,
                action: BackendAction.insert
            })]
        }));
    }


    @action
    export = async (handleNoContent: () => void): Promise<void> => {
        try {
            this.startLoading("client.loadingExport");
            const exportData = await this._exportDataUserUseCase.call(this.role);
            if (exportData.responseStatus === 204) {
                handleNoContent();
                return;
            }
            this.setExportation(exportData);
            if (exportData.inProgress) {
                this.observeExport();
            }
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            if (err.message === 'need_admin_privileges') {
                navigate(this.role === UserRole.attendant ? RoutesEnum.users : RoutesEnum.contacts);
            }
            this.stopLoading();
        }
    }

    @action
    observeExport() {
        this.subscriptionExport = this._exportUsersSubscriptionUseCase.call().subscribe({
            next: (response) => {
                this.setExportation(response);
            },
            error: (err) => {
                toastMessage.error(err);
                this.stopLoading();
            }
        });
    }

    @action
    dispose = (): void => {
        this.subscriptionExport?.unsubscribe();
    }

    @action
    onDeleteSectorsLinked = (id: string): void => {
        const index = this.user!.sectors.findIndex((entry) => id === entry.idSector && entry.action !== BackendAction.delete);
        if (index > -1) {
            const _sectors = this.user!.sectors;

            if (_sectors[index].action === BackendAction.insert) {

                _sectors.splice(index, 1);
                this.setUser(this.user!.copyWith({
                    sectors: _sectors
                }))

            } else {

                const _sectorUser = _sectors[index].copyWith({action: BackendAction.delete, replaceActionIfNull: true});
                _sectors[index] = _sectorUser;
                this.setUser(this.user!.copyWith({
                    sectors: _sectors
                }));

            }

        }
    }

    @action
    onChangeSectorsLinked = (sector: SectorEntity): void => {
        const index = this.user!.sectors.findIndex((entry) => sector.id === entry.idSector);
        if (index > -1) {

            const _sectors = this.user!.sectors;
            const _sectorUser = _sectors[index].copyWith({action: BackendAction.update, replaceActionIfNull: true});
            _sectors[index] = _sectorUser;
            this.setUser(this.user!.copyWith({
                sectors: _sectors
            }));

        } else {

            this.onInsertSectorsLinked(sector);

        }
    }
}
